.wrapper {
  composes: responsivenessClass from global;
  padding: 40px var(--desktopSidePaddingSmall);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  @media (min-width: 1025px) {
    padding: 50px var(--desktopSidePaddingSmall);
  }

  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.ctaButton {
  composes: buttonDefault from global;
  max-width: 200px;
  margin-top: 50px;
  &:hover {
    text-decoration: none;
  }
}

.cover {
  object-fit: cover;
  width: 50%;
  height: auto;
  border-radius: 15px;

  @media (max-width: 1025px) {
    width: 100%;
  }
}

.content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: black;

  & h2 {
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin: 0;
  }

  & p {
    font-size: 22px;
    line-height: 27px;
    color: #4e4e4f;
    margin: 0;
    margin-top: 10px;
  }

  @media (max-width: 1025px) {
    width: 100%;
  }
}
