.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgb(34, 34, 35, 0.48);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  position: relative;
  @media (max-width: 1025px) {
    height: 95vh;
    width: 95vw;
    overflow: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
}

.content::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.close {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
}
