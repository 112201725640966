.wrapper {
  composes: responsivenessClass from global;
  padding: 40px var(--desktopSidePaddingSmall);

  @media (min-width: 1025px) {
    padding: 50px var(--desktopSidePaddingSmall);
  }
}

.progress {
  width: 100%;
  margin-bottom: 50px;

  @media (max-width: 1025px) {
    display: none;
  }
}

.progressMobile {
  display: none;
  width: 100%;
  margin-bottom: 50px;
  @media (max-width: 1025px) {
    display: block;
  }
}

.title {
  composes: sectionTitle from global;
  margin-top: 0;
}
