.wrapper {
  position: relative;
  opacity: 0;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

.banner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: white;
  border-radius: 15px;
  width: 100%;
  max-width: 400px;
  padding-bottom: 15px;

  & p {
    font-size: 20px;
    line-height: 25px;
    margin-top: 15px;
    padding: 0 15px;
    color: black;
  }

  @media (max-width: 1025px) {
    width: 90%;
  }
}

.closeWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.close {
  margin: 15px 15px 0 0;
  height: 30px;
  width: auto;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
}

.cover {
  width: 100%;
  height: 200px;
  border-radius: 15px 15px 0 0;
  object-fit: cover;
}

.arrow {
  height: 30px;
  width: auto;
}

.cta {
  font-weight: 700;
  padding: 0 15px;
}
