.wrapper {
  width: 100%;
  composes: responsivenessClass from global;
  padding: 40px var(--desktopSidePaddingSmall);
  background-color: #f9f9f9;
  @media (min-width: 1125px) {
    padding: 100px var(--desktopSidePaddingSmall);
  }
}

.listingCardsMapVariant {
  padding: 0 0 96px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCards {
  padding: 0 0 72px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1025px) {
    padding-bottom: 0;
  }
}

.listingCard {
  width: 100%;
}

.textBox {
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  color: #222223;
  width: 100%;
  margin-bottom: 40px;
  @media (max-width: 1125px) {
    width: 100%;
    margin-bottom: 25px;
  }
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1025px) {
    margin-top: 40px;
    justify-content: space-around;
  }

  & svg {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.seeMoreButton {
  max-width: 250px;
  border-radius: 8px;
  @media (max-width: 900px) {
    max-width: 60%;
  }
}
