.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1025px) {
    margin-top: 60px;
  }
}

.logosWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.imageWrapper {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: scale(1.03);
  }
}

.logo {
  height: auto;
  width: 70%;
  max-height: 120px;
  @media (max-width: 1025px) {
    height: auto;
    width: 80%;
  }
}

.title {
  width: 100%;
  text-align: center;
  color: black;
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
  margin-top: 0;
  padding-top: 0;

  & span {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }

  @media (max-width: 1025px) {
    margin-bottom: 40px;
  }
}
