.wrapper {
  composes: responsivenessClass from global;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f9f9f9;

  padding: 40px var(--desktopSidePaddingSmall);

  @media (min-width: 1025px) {
    padding: 100px var(--desktopSidePaddingSmall);
  }

  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.sectionLeft {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 150px;
  & h1 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    color: #222223;
  }

  & p {
    color: #222223;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  @media (max-width: 1025px) {
    width: 100%;
    padding-right: 0;
  }
}

.sectionRight {
  height: 100%;
  width: 50%;
  position: relative;

  @media (max-width: 1025px) {
    margin-top: 20px;
    width: 100%;
  }
}

.contactLine {
  margin-bottom: 10px;

  & a svg {
    margin-right: 20px;
    transform: scale(1.2);
    &:hover {
      transform: scale(1.5);
    }
  }
}

.name {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.name .field {
  width: 45%;

  @media (max-width: 1025px) {
    width: 100%;
  }
}

.field {
  margin-bottom: 20px;
}
