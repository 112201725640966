@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.minTopMargin {
  margin-top: 10px;
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.field {
  margin-top: 32px;
}

.hbContactTitle {
  font-weight: var(--fontWeightSemiBold);
  line-height: 16px;
  font-size: 13px;
  margin-top: 32px;
}

.designAlertsField {
  margin-top: 32px;

  & legend {
    font-weight: var(--fontWeightSemiBold);
    line-height: 16px;
    font-size: 13px;
  }

  & ul {
    display: flex;
  }

  & li {
    margin-right: 20px;
  }
}
