.wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;

  composes: responsivenessClass from global;
  padding: 40px var(--desktopSidePaddingSmall);

  @media (min-width: 1025px) {
    padding: 100px var(--desktopSidePaddingSmall);
    padding-bottom: 50px;
  }
}

.title {
  width: 100%;
  composes: sectionTitle from global;
  margin-top: 0;

  @media (max-width: 1025px) {
    display: none;
  }
}

.titleMobile {
  display: none;
  width: 100%;
  composes: sectionTitle from global;
  margin-top: 0;

  @media (max-width: 1025px) {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
}

.sectionTop {
  width: 100%;
  display: flex;

  @media (max-width: 1150px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
  }
}

.sectionContent {
  width: 70%;
  padding-left: 40px;
  @media (max-width: 1025px) {
    padding-left: unset;
    width: 100%;
    order: 2;
    margin-top: 40px;
  }
}

.youtubeIframe {
  width: 50%;
  object-fit: cover;
  border-radius: 10px;

  @media (max-width: 1150px) {
    height: 50vh;
    width: 100%;
    order: 1;
  }
}

.listWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.listItemWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 1025px) {
    flex-direction: column;
    margin-bottom: 60px;
  }
}

.itemIcon {
  height: 40px;
  margin-right: 20px;

  @media (max-width: 1025px) {
    width: 80px;
    height: auto;
    margin: 0;
  }
}

.itemTitle {
  margin: 0;
  font-weight: 700;

  @media (max-width: 1025px) {
    text-align: center;
    font-size: 22px;
    margin-top: 20px;
  }
}

.itemDescription {
  margin: 0;
  padding: 0;

  @media (max-width: 1025px) {
    font-size: 20px;
    margin-top: 10px;
    text-align: center;
  }
}

.itemContent {
  padding-right: 10px;
}

.itemButton {
  border: 2px solid var(--marketplaceColor);
  border-radius: 5px;
  padding: 5px 10px;
  color: var(--marketplaceColor);
  font-weight: 700;
  cursor: pointer;
  margin: 0 0 0 auto;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    transform: scale(1.01);
  }

  @media (max-width: 1025px) {
    min-width: none;
    width: 100%;
    max-width: 300px;
    margin: 20px auto 0 auto;
    font-size: 20px;
    padding: 10px 15px;
  }
}
