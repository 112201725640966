.wrapper {
  position: relative;
  background-color: white;
  display: flex;
  transform: scale(0.9);
  @media (max-width: 1025px) {
    flex-direction: column;
    transform: scale(1);
  }
}

.formWrapper {
  padding: 60px 20px 20px 20px;
  max-width: 500px;
  @media (max-width: 1025px) {
    padding: 20px;
  }
}

.title {
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  color: black;
}

.redText {
  color: var(--marketplaceColor);
}

.error {
  width: 100%;
  text-align: center;
  color: red;
}

.field {
  margin-bottom: 20px;
}

.privacy {
  font-size: 14px;
  color: #777777;
}

.submit {
  margin-top: 10px;
}

.footprint {
  color: black;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0;
}

.image {
  object-fit: cover;
}
