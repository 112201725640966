.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  composes: responsivenessClass from global;
  padding: 40px var(--desktopSidePaddingSmall);

  @media (min-width: 1025px) {
    padding: 100px var(--desktopSidePaddingSmall);
  }

  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.leftWrapper {
  width: 50%;
  padding-right: 40px;

  @media (max-width: 1025px) {
    padding: 0;
    width: 100%;
  }
}

.rightWrapper {
  width: 50%;
  @media (max-width: 1025px) {
    width: 100%;
  }
}

.title {
  composes: sectionTitle from global;

  @media (max-width: 1025px) {
    width: 100%;
    text-align: center;
  }
}

.subtitle {
  font-size: 22px;
  line-height: 27px;
  color: black;

  @media (max-width: 1025px) {
    font-size: 20px;
    line-height: 25px;
    width: 100%;
    text-align: center;
  }
}

.redText {
  color: var(--brickColor);
}

.ctaButton {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--marketplaceColor);
  color: white;
  width: 50%;
  &:hover {
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
  }

  @media (max-width: 1025px) {
    width: 100%;
  }
}

.divider {
  background-color: #777777;
  height: 80%;
  width: 1px;
}
