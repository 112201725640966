.pageRoot {
  background-color: white;
}

.close {
  position: absolute;
  z-index: 1;
  top: 6%;
  right: 6%;
  cursor: pointer;
  @media (max-width: 1025px) {
    top: 8%;
    right: 8%;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.graySeparator {
  width: 100%;
  height: 20px;
  background-color: #f9f9f9;
}
