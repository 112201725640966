.wrapper {
  width: 100%;
  min-height: 60vh;
  border-radius: 0 0 53px 53px;
  background: linear-gradient(180deg, #2274b5 0%, #81c2ec 71.43%);
  padding-top: calc(var(--topbarHeightDesktop) + 40px);

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 1025px) {
    padding-top: 120px;
    flex-direction: column;
  }

  @media (min-width: 1025px) and (max-width: 1250px) {
    height: 75vh;
  }

  @media (min-width: 1025px) and (max-width: 1250px) and (min-height: 950px) {
    height: 65vh;
  }

  @media (min-width: 1700px) {
    height: 67vh;
  }
}

.heroLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 40%;
  padding-left: var(--desktopSidePaddingSmall);
  padding-top: 40px;
  @media (min-width: 1025px) and (max-width: 1420px) {
    padding-top: 0;
  }

  @media (max-width: 1025px) {
    padding: 0 20px;
    width: 100%;
  }
}

.housePic {
  width: 60%;
  border-radius: 0 0 53px 0;
  @media (max-width: 1025px) {
    display: none;
  }
}

.homePicMobile {
  display: none;
  @media (max-width: 1025px) {
    display: unset;
    width: 100%;
  }
}

.title {
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  width: 35vw;
  color: #222223;
  font-family: Georgia, 'Times New Roman', Times, serif;

  /* @media (min-width: 1025px) and (max-width: 1420px) { */
  margin-top: 0;
  /* } */

  @media (max-width: 1430px) {
    width: unset;
  }

  @media (max-width: 1400px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: 1025px) {
    width: 100%;
    font-size: 22px;
    line-height: 40px;
    margin-top: 20px;
    text-align: center;
  }
}

.checksSection {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @media (max-width: 1025px) {
    flex-direction: column;
    margin-bottom: 0;
  }

  @media (max-width: 1150px) {
    width: 100%;
  }

  @media (min-width: 1025px) and (max-width: 1250px) {
    display: none;
  }
}

.checkItem {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--222223, #222223);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  @media (max-width: 1150px) {
    width: 50%;
  }

  @media (min-width: 1250px) and (max-width: 1400px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.checkItem1 {
  composes: checkItem;
  width: 60%;
  @media (max-width: 1025px) {
    width: 100%;
  }
}

.checkItem2 {
  composes: checkItem;
  width: 40%;
  @media (max-width: 1025px) {
    width: 100%;
  }
}

.checkIcon {
  margin-right: 10px;
  max-height: 25px;
}

.cta {
  max-width: 200px;
  margin-right: 20px;
  @media (max-width: 1025px) {
    max-width: unset;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
}

.buttonsWrapper {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.buttonsWrapper2 {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  @media (max-width: 1025px) {
    justify-content: flex-start;
  }
}

.ctaDark {
  background-color: transparent;
  color: black;
  border: 3px solid black;
  max-width: 200px;
  margin-right: 20px;
  @media (max-width: 1025px) {
    margin-left: auto;
    margin-right: auto;
    max-width: unset;
    width: 45%;
  }

  &:hover {
    color: white;
  }
}

.bigText {
  composes: sectionTitle from global;

  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  color: #222223;
}
