.wrapper {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  composes: responsivenessClass from global;
  padding: 40px var(--desktopSidePaddingSmall);

  @media (max-width: 1150px) {
    flex-direction: column;
  }

  @media (min-width: 1025px) {
    padding: 50px var(--desktopSidePaddingSmall);
  }
}

.sectionLeft {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 20px;
  & h1 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    color: var(--222223, #222223);
    & span {
      color: var(--f-85757, #f85757);
    }
  }

  & p {
    color: var(--222223, #222223);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  @media (max-width: 1150px) {
    width: 100%;
    margin: 0;
  }
}

.buttonBase {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pinkButton {
  composes: buttonBase;
  border-radius: 8px;
  background: var(--marketplaceColor);
  color: white;
  margin-right: 20px;

  @media (max-width: 1025px) {
    width: 100%;
  }

  &:hover {
    background-color: var(--marketplaceColorLight);
    text-decoration: none;
  }
}

.checksSection {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @media (max-width: 1150px) {
    width: 100%;
  }

  @media (max-width: 1025px) {
    flex-direction: column;
  }
}

.checkItem {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--222223, #222223);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  @media (max-width: 1150px) {
    width: 50%;
    margin-bottom: 10px;
  }

  @media (max-width: 1025px) {
    width: 100%;
  }
}

.checkIcon {
  margin-right: 10px;
}

.sectionRight {
  height: 100%;
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;

  @media (max-width: 1150px) {
    width: 100%;
    min-height: unset;
    height: auto;
    margin: 150px 0 0 0;
  }

  @media (max-width: 550px) {
    min-height: unset;
    height: auto;
    margin-top: 30vw;
  }
}

.imageTop {
  width: 60%;
  object-fit: contain;
  margin-top: -80px;
  margin-right: -20%;
  z-index: 5;
}

.imageBottom {
  width: 60%;
  object-fit: contain;
  margin-bottom: -80px;
}
