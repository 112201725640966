.wrapper {
  composes: responsivenessClass from global;
  padding: 40px var(--desktopSidePaddingSmall);

  @media (min-width: 1025px) {
    padding: 50px var(--desktopSidePaddingSmall);
  }
}

.title {
  composes: sectionTitle from global;
  margin-top: 0;

  @media (max-width: 1025px) {
    margin-bottom: 40px;
  }
}

.progress {
  width: 100%;
  margin-bottom: 50px;

  @media (max-width: 1025px) {
    display: none;
  }
}

.progressMobile {
  width: 100%;
  margin-bottom: 50px;
  display: none;
  @media (max-width: 1025px) {
    display: block;
  }
}

.cardsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 1025px) {
    grid-template-columns: 1fr;
  }
}

.cardWrapper {
  width: 100%;
  border-radius: 20px;
  box-shadow: var(--boxShadowButton);
}

.cardImage {
  width: 100%;
  height: 60%;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
}

.cardTitle {
  color: black;
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  padding: 0 20px;
}

.cardDescription {
  padding: 0 20px;
}

.ctaButton {
  margin-top: 40px;
  max-width: 300px;

  @media (max-width: 1025px) {
    max-width: unset;
    width: 100%;
  }
}
