.wrapper {
  position: relative;
  composes: responsivenessClass from global;
  padding-top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px var(--desktopSidePaddingSmall);
  background-color: #f9f9f9;
  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.card {
  display: flex;
  flex-direction: column;

  border-radius: 20px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.08);
  width: 400px;
  margin-top: -195px; /* added 5px from the border */
  color: white;
  transform: scale(0.9);
  margin-left: -10px;
  @media (max-width: 1025px) {
    width: 100%;
    margin-left: 0;
  }
}

.cardTop {
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: #9e4505;
  padding: 20px 0 0 20px;
  border-radius: 20px 20px 0 0;
  border-top: 5px solid #fff;
  border-left: 5px solid #fff;
  border-right: 5px solid #fff;
}

.cardTopL {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  padding-bottom: 20px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1265px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
  }
}

.cardImage {
  height: 200px;
  width: auto;
}

.cardBottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #ee6e12;
  border-radius: 0 0 20px 20px;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  height: 230px;
  border-bottom: 5px solid #fff;
  border-left: 5px solid #fff;
  border-right: 5px solid #fff;

  @media (max-width: 1265px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
  }
}

.buttonBase {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--black-tmb, #222223);
  margin-top: 20px;
  text-align: center;
  padding: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  &:hover {
    text-decoration: none;
    transform: scale(1.01);
  }
}

.buttonWhite {
  composes: buttonBase;
  color: black;
  background-color: white;
}

/* SECOND CARD */

.cardSecond {
  composes: card;

  @media (max-width: 1025px) {
    margin-top: 80px;
  }
}

.cardTopS {
  composes: cardTop;
  background-color: #a53a3a;
}

.cardBottomS {
  composes: cardBottom;
  background-color: #f85757;
}

.buttonBlack {
  composes: buttonBase;
  color: white;
  background-color: black;
}

.cardImageS {
  composes: cardImage;
  height: 220px;
  margin-right: -15px;
}

.reducedHeightCardBottom {
  height: 160px;
}

.cardMarginAdjustment {
  margin-top: -191px;
}

.cardSecondMarginAdjustment {
  margin-top: -191px;

  @media (max-width: 1025px) {
    margin-top: 80px;
  }
}
