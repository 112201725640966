.formsWrapper {
  display: flex;
  width: 100%;

  & select {
    background-image: none;
  }

  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
  }
}

.left {
  width: 50%;

  @media (max-width: 1025px) {
    width: 100%;
  }
}

.right {
  width: 50%;
  @media (max-width: 1025px) {
    width: 100%;
  }
}

.divider {
  background-color: #d1d1d1;
  height: 200px;
  width: 1px;
  margin: auto 0;

  @media (max-width: 1025px) {
    width: 100%;
    height: 1px;
  }
}

.underText {
  font-size: 16px;
  line-height: 24px;
  padding: 0 20px;
}
