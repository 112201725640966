.wrapper {
  composes: responsivenessClass from global;
  padding: 40px var(--desktopSidePaddingSmall);

  @media (min-width: 1025px) {
    padding: 50px var(--desktopSidePaddingSmall);
  }
}

.progress {
  width: 100%;
  margin-bottom: 50px;

  @media (max-width: 1025px) {
    display: none;
  }
}

.progressMobile {
  display: none;
  width: 100%;
  margin-bottom: 50px;
  @media (max-width: 1025px) {
    display: block;
  }
}

.title {
  composes: sectionTitle from global;
  margin-top: 0;
}

.subtitle {
  font-size: 22px;
  line-height: 27px;
  color: black;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1025px) {
    flex-direction: column;
  }
}

.ctaButton {
  max-width: 300px;
  margin-right: 40px;

  @media (max-width: 1025px) {
    max-width: unset;
    width: 100%;
    margin: 0 0 20px 0;
  }
}
