@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBottomWrapperText from global;
  padding-bottom: 6px;
  margin-top: 32px;
  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.finePrint {
  composes: marketplaceModalHelperLink from global;
  padding: 3px 0 5px 0;
  text-align: left;
  font-weight: var(--fontWeightSemiBold);
  line-height: 16px;
  font-size: 13px;
}

.privacyLink,
.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: var(--marketplaceColor);

  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: underline;
    cursor: pointer;
  }
}
